import React from "react"

import Layout from "../components/layout"
import { Hero } from "../components/hero/Hero"
import { Activities } from "../components/activities/Activities"
import { Contact } from "../components/contact/Contact"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Activities />
    <Contact />
  </Layout>
)

export default IndexPage
