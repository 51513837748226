import React from 'react'
const activities = [
  'Slopen',
  'Stuccen',
  'Schilderen',
  'Badkamers',
  'Keukens',
  'Vloeren',
  'Uitbouw',
  'Loodgieterswerkzaamheden',
  'Tuinwerk',
  'Noodgevallen',
  'Hoogwerk',
  'Betegelen',
  'Ornamenten',
  'CV Installatie',
  'Leiding',
  'Elektrische bedrading',
]

export const Activities = () => (
  <section className="activities">
    <header className="section-header">
      <h1 className="section-header__title">Werkzaamheden</h1>
      <p className="section-header__subtitle">
        Eigenlijk doen wij alles!
        <br /> Voor meer informatie kunt u contact opnemen
      </p>
    </header>
    <ul className="activities__list">
      {activities.map(activity => (
        <li key={activity} className="activities__item">
          {activity}
        </li>
      ))}
    </ul>
  </section>
)
