import React from 'react'

export const Hero = () => (
  <section className="hero">
    <h1 className="hidden">Klusbedrijf Maric</h1>
    <h2 className="hidden">
      Voor al uw kleine &egrave; grote klussen in en rondom Rotterdam
    </h2>
  </section>
)
